@import '../../../variables';

.page-title {
  margin: 0 0 24px;
  background: #f1f1f1;
}

.container {
  margin: 0 auto;
  color: #181818;
}

.inner {
  display: flex;
  align-items: center;
  padding: 12px 60px;
  @media #{$screen-xs-only} {
    width: 95%;
    padding: 52px 0 12px;
    margin: 0 auto;
  }
}

.title,
.calendar-value {
  margin: 0;
  font-size: 1.5em;
  font-weight: 400;
  text-transform: uppercase;

  b {
    font-weight: 900;
  }
}

.calendar-value {
  display: flex;
  align-items: center;
  padding: 0;
  margin-left: 15px;
  background: transparent;
  border: 0;
  transition: color 0.25s ease;

  &:hover {
    color: $red-color;
  }

  &::before {
    content: '';
    display: block;
    height: 50px;
    margin-right: 15px;
    border-left: 2px solid #e80c30;
    @media #{$screen-lg-max} {
      height: 36px;
    }
    @media #{$screen-xs-only} {
      height: 24px;
    }
  }

  svg {
    display: block;
    width: 18px;
    height: 28px;
    margin-left: 5px;
    fill: currentColor;
    transition: all 0.5s ease;
    @media #{$screen-xs-only} {
      width: 12px;
      height: 20px;
    }
  }
}

.calendar-value-active {
  svg {
    transform: rotate(180deg);
  }
}

.calendar {
  position: relative;
}

.history-title {
  .container {
    max-width: 1140px;
    @media #{$screen-lg-max} {
      max-width: 620px;
    }
    @media #{$screen-xs-only} {
      width: calc(100% - 40px);
    }
  }

  .inner {
    display: flex;
    align-items: center;
    padding: 12px 0;
  }

  .title,
  .calendar-value {
    font-size: 2em;
    @media #{$screen-lg-max} {
      font-size: 1.5em;
    }
    @media #{$screen-xs-only} {
      font-size: 1em;
    }
  }
}
