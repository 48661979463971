@import '../../../variables';

.container {
  position: relative;
  max-width: 790px;
  margin: 0 auto;
  color: #555;

  @media #{$screen-sm-max} {
    max-width: 90%;
  }
}

.date {
  display: block;
  padding-bottom: 12px;
  text-decoration: none;

  &,
  &:active,
  &:visited {
    color: inherit;
  }

  &:hover {
    @media (min-width: 1025px) {
      color: #e80c30;
    }
  }

  &.active {
    color: #000;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 4px;
      background-color: #f04f69;
    }
  }
}

.date-day-month {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;

  @media #{$screen-sm-max} {
    margin-bottom: 6px;
    font-size: 18px;
  }
}

.date-week {
  font-size: 15px;
  text-transform: uppercase;

  @media #{$screen-sm-max} {
    font-size: 13px;
  }
}

.nav-button {
  position: absolute;
  top: 7px;
  right: 0;
  display: block;
  width: 24px;
  padding: 0;
  font-family: 'icon-fonts';
  font-size: 22px;
  text-align: center;
  background: none;
  border: 0;
  transition: all 0.3s ease-out;

  &:hover {
    opacity: 0.8;
    transform: scale(1.2);
  }

  &.disabled {
    visibility: hidden;
  }

  &.prev {
    left: -40px;

    @media #{$screen-sm-max} {
      left: -35px;
    }

    &::after {
      content: 'E';
    }
  }

  &.next {
    right: -40px;

    @media #{$screen-sm-max} {
      right: -35px;
    }

    &::after {
      content: 'D';
    }
  }
}
