@import '../../../variables';

.container {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  z-index: 35;
  width: 278px;
  // display: none;
  padding: 5px;
  font-size: 19px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.33);

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 50%;
    display: block;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-width: 0 5px 5px;
    border-bottom-color: #fff;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  text-transform: uppercase;
}

.days {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(7, 34px);
  width: auto;
}

.day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  font-size: inherit;
  background: transparent;
  border: 0;
  transition: background 0.3s ease;

  &:hover {
    background: #b6b6b6;
  }

  &.current {
    color: #fff;
    background-color: $red-color;
  }
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  font-family: 'icon-fonts';
  font-size: 17px;
  font-weight: 400;
  background: transparent;
  border: 0;
  transition: background 0.3s ease;

  &:hover {
    background: #b6b6b6;
  }

  &.prev {
    &::after {
      content: 'E';
    }
  }

  &.next {
    &::after {
      content: 'D';
    }
  }
}
