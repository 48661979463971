@import '../../../variables';

.container {
  padding: 16px;
  margin-bottom: 2px;

  &:nth-child(odd) {
    background: #fff;
  }

  &:nth-child(even) {
    background: #e7e7e8;
  }
}

.time {
  width: 13.06%;
  font-size: 14px;
  font-weight: 300;
  color: #111;
  text-align: right;
}

.preview-content-details {
  width: calc(76.736% - 10px);
  padding: 0 16px;
}

.show-title {
  display: inline-block;
  width: calc(100% - 175px);
  max-width: 100%;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media #{$screen-sm-max} {
    width: 100%;
  }
}

.episode-title {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 600;
}

.content {
  display: flex;
  max-height: 0;
  overflow: hidden;
  font-size: 14px;
  transition: max-height 0.3s ease;
  transition-duration: 300ms;

  &::before {
    content: '';
    width: 13.06%;
  }

  &.expanded {
    max-height: 400px;
  }
}

.content-inner {
  width: calc(76.736% - 10px);
  padding: 0 16px;

  img {
    display: block;
    max-width: 100%;
    margin-bottom: 10px;
  }
}

.preview {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  text-align: left;
  cursor: pointer;
  background: none;
  border: 0;
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: 0.7;
  }
}

.preview-content {
  display: flex;
}

.description {
  line-height: 21.28px;
}

.show-number {
  margin: 10px 0;
}

.show-rating {
  margin-bottom: 10px;
}

.arrow {
  position: absolute;
  top: 5px;
  right: 14px;
  line-height: 0;
  transition: transform 0.3s ease;
  transform-origin: center;

  &::before {
    content: 'k';
    font-family: 'icon-fonts';
    font-size: 30px;
  }
}

.expanded {
  .arrow {
    transform: rotate(180deg);
  }
}
