@import '../variables';

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.locked {
    position: fixed;
    height: 100%;
    min-height: auto;
  }
}

.content {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  opacity: 1;
}

.content-inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}

.content-page {
  flex: 1;
}
