@import './variables';
@import '~swiper/swiper';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans');

@font-face {
  font-family: 'icon-fonts';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/icon-fonts.woff');
  src: url('/assets/fonts/icon-fonts.woff?#iefix') format('embedded-opentype'),
    url('/assets/fonts/icon-fonts.woff') format('woff'),
    url('/assets/fonts/icon-fonts.ttf') format('truetype'),
    url('/assets/fonts/icon-fonts.svg#icon-fonts') format('svg');
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $default-font-family;
  font-size: $default-font-size;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.locked {
    overflow: hidden;
  }
  @media #{$screen-xs-only} {
    font-size: $default-font-size-xs;
  }
}

a,
a:hover,
a:visited,
a:active {
  color: $red-color;
}

a {
  outline: none;

  &:focus-visible {
    outline: auto;
  }
}

h1 {
  font-size: 47px;
  font-weight: 900;
  @media #{$screen-xs-only} {
    font-size: 28px;
    line-height: 1.25;
  }
}

h2 {
  font-size: 35px;
  font-weight: 900;
  line-height: 1.2;
  @media #{$screen-xs-only} {
    font-size: 28px;
  }
}

h3 {
  margin: 0 0 $default-padding;
  font-size: 21px;
  @media #{$screen-xs-only} {
    font-size: 12px;
  }
}

h4 {
  margin: 0 0 10px;
  font-size: 19px;
}

button {
  font-family: inherit;
  cursor: pointer;
  outline: none;

  &:focus-visible {
    outline: auto;
  }
}

li {
  outline: none;
}

input,
textarea {
  font-family: $default-font-family;
}

.limited {
  max-width: $limit;
  margin: 0 auto;
  @media #{$screen-sm-only} {
    margin: 0 $side-padding-sm;
  }
  @media #{$screen-xs-only} {
    margin: 0 $side-padding-xs;
  }
}

.link {
  @include link;
}

.related {
  position: relative;
}

// colors
.red-color {
  color: $red-color;
}

// responsive
.screen-sm-max {
  @media #{$screen-sm-min} {
    display: none;
  }
}

.screen-sm-only {
  @media #{$screen-xs-only} {
    display: none;
  }
  @media #{$screen-md-min} {
    display: none;
  }
}

.screen-sm-min {
  @media #{$screen-xs-only} {
    display: none;
  }
}

.screen-xs-only {
  @media #{$screen-sm-min} {
    display: none;
  }
}

.screen-md-min {
  @media #{$screen-sm-max} {
    display: none;
  }
}

// flex
.flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.col-1 {
  flex: 1 0 100%/12;
}

.col-2 {
  flex: 1 0 100%/12 * 2;
}

.col-3 {
  flex: 1 0 100%/12 * 3;
}

.col-4 {
  flex: 1 0 100%/12 * 4;
}

.col-5 {
  flex: 1 0 100%/12 * 5;
}

.col-6 {
  flex: 1 0 100%/12 * 6;
}

.col-7 {
  flex: 1 0 100%/12 * 7;
}

.col-8 {
  flex: 1 0 100%/12 * 8;
}

.col-9 {
  flex: 1 0 100%/12 * 9;
}

.col-10 {
  flex: 1 0 100%/12 * 10;
}

.col-11 {
  flex: 1 0 100%/12 * 11;
}

.col-12 {
  flex: 1 0 100%/12 * 12;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  white-space: nowrap;
  border: 0;
}

.embed-responsive {
  position: relative;
  display: block;
  height: auto;
  padding: 56.25% 0 0;
  margin-bottom: 20px;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
