@import '../../variables';

.footer {
  padding: 60px $page-side-padding 30px;
  color: $white-color;
  background-color: $black-color;

  @media #{$screen-lg-max} {
    padding: 60px $page-side-padding-md 30px;
  }
  @media #{$screen-sm-max} {
    padding: 60px $page-side-padding-sm 30px;
  }
  @media #{$screen-xs-only} {
    padding: 16px $page-side-padding-xs;
  }
}

.footer-wrap {
  display: grid;
  grid-template-columns: 350px 1fr 350px;
  @media #{$screen-sm-max} {
    grid-template-columns: 220px 1fr 220px;
  }
  @media #{$screen-xs-only} {
    grid-template-columns: 1fr;
  }
}

.footer-item {
  &:nth-of-type(2) {
    align-self: center;
    text-align: center;
    @media #{$screen-xs-only} {
      align-self: flex-start;
      order: -1;
      text-align: left;
    }
  }

  &:last-of-type {
    justify-self: end;
    text-align: right;
    @media #{$screen-xs-only} {
      justify-self: flex-start;
      text-align: left;
    }
  }
}

.footer-menu {
  padding: 0;
  margin: 0;
  list-style: none;

  @media #{$screen-xs-only} {
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 12px;

    @media #{$screen-sm-max} {
      margin-bottom: 8px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .footer-menu-link {
      font-size: 14px;
      line-height: 19px;
      color: $white-color;
      text-decoration: none;
      text-transform: uppercase;
      transition: $base-transition;

      @media #{$screen-sm-max} {
        font-size: 12px;
        line-height: 14px;
      }

      &:hover {
        color: $red-color;
      }
    }
  }
}

.footer-link {
  display: inline-block;
}

.footer-logo {
  display: block;
  width: 77px;
  @media #{$screen-sm-max} {
    width: 51px;
  }
  @media #{$screen-xs-only} {
    margin-bottom: 20px;
  }
}

.footer-socials {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 0 0 50px;
  list-style: none;
  @media #{$screen-sm-max} {
    margin-bottom: 20px;
  }
  @media #{$screen-xs-only} {
    justify-content: flex-start;
    margin-bottom: 24px;
  }
}

.footer-social {
  display: inline-block;
  margin-left: 19px;
  @media #{$screen-xs-only} {
    margin: 0 19px 0 0;
  }

  svg path {
    transition: $base-transition;
    stroke: $white-color;
  }

  &:hover {
    svg path {
      stroke: $red-color;
    }
  }
}

.footer-copyright {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  @media #{$screen-xs-only} {
    max-width: 225px;
  }
}

.footer-subtitle {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  text-transform: uppercase;
  @media #{$screen-sm-max} {
    font-size: 12px;
    line-height: 16px;
  }
  @media #{$screen-xs-only} {
    margin-bottom: 8px;
    text-align: left;
  }
}
