@import '../../variables';

.container {
  // todo
}

.inner {
  width: 88.8%;
  margin: 0 auto;
}
