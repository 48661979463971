@import '../../variables';

.container {
  // todo
}

.featured {
  display: flex;
  flex-wrap: wrap;
  background: linear-gradient(#111, #333);
}
