@import '../../variables';

@keyframes draw {
  0% {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -474;
    stroke-width: 4px;
  }

  100% {
    stroke-dasharray: 760;
    stroke-dashoffset: 0;
    stroke-width: 2px;
  }
}

.connect-page {
  padding: 42px $page-side-padding 100px;
  @media #{$screen-lg-max} {
    padding: 40px $page-side-padding-md 80px;
  }
  @media #{$screen-sm-max} {
    padding: 50px $page-side-padding-sm;
  }
  @media #{$screen-xs-only} {
    padding: 30px $page-side-padding-xs;
  }
}

.page-title {
  padding: 0;
  margin: 0 0 30px;
  font-size: 60px;
  font-weight: normal;
  line-height: 82px;
  @media #{$screen-sm-max} {
    margin-bottom: 0;
    font-size: 42px;
    line-height: 57px;
  }
  @media #{$screen-xs-only} {
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 44px;
  }
}

.lead-text {
  margin: 0 0 100px;
  font-size: 30px;
  line-height: 41px;
  @media #{$screen-lg-max} {
    margin-bottom: 80px;
  }
  @media #{$screen-sm-max} {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 22px;
  }
  @media #{$screen-xs-only} {
    margin-bottom: 32px;
  }
}

.providers-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-gap: 40px;
  padding: 0;
  margin: 0;
  list-style: none;
  @media #{$screen-lg-max} {
    grid-column-gap: 33px;
    grid-row-gap: 52px;
  }
  @media #{$screen-sm-max} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 80px;
    grid-row-gap: 52px;
  }
  @media #{$screen-xs-only} {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.provider {
  a {
    display: block;
    min-height: 100%;
    padding-bottom: 50px;
    text-align: center;
    border: 1px solid $black-color;
    @media #{$screen-xs-only} {
      padding-bottom: 30px;
    }

    @media (min-width: 1025px) {
      &:hover .shape {
        animation-name: draw;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
      }
    }
  }
}

.provider-logo {
  position: relative;
  padding-top: 65%;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }
}

.link-text {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  line-height: 27px;
  color: $black-color;
  text-align: center;
  letter-spacing: 0.1em;
  white-space: nowrap;
  transform: translate(-50%, -50%);
}

.svg-wrapper {
  position: relative;
  top: 50%;
  width: 320px;
  height: 60px;
  margin: 0 auto;
  transform: translateY(-50%);
}

.shape {
  fill: transparent;
  stroke-dasharray: 205 540;
  stroke-dashoffset: -440;
  stroke-width: 4px;
  stroke: $dark-red-color;
}
