@import '../../variables';

.history-page {
  //todo
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  @media #{$screen-lg-max} {
    max-width: 620px;
  }
  @media #{$screen-xs-only} {
    width: calc(100% - 40px);
  }
}

.history-detail {
  max-width: 700px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.history-title {
  margin: 0 0 30px;
  font-size: 43px;
  font-weight: 400;
  line-height: 1.21em;
  @media #{$screen-xs-only} {
    margin-bottom: 20px;
    font-size: 32px;
  }
}

.history-detail-fig {
  width: 100%;
  max-width: 700px;
  margin: 0 0 20px;

  img {
    display: block;
    max-width: 100%;
    margin: 0 0 20px;
  }
}

.history-body {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.65em;
  letter-spacing: 0.05rem;

  p {
    margin: 0 0 30px;
  }
}

.history-footer {
  width: 100%;
}

.citation-title {
  margin-bottom: 26px;
  font-size: 32px;
  font-weight: 400;
  line-height: 1.21em;
}

.citation-meta {
  margin-bottom: 20px;
}

.citation-subtitle {
  margin: 0 0 5px;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.32em;
}

.citation-text {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.05rem;
}

.additional-attributes {
  margin-bottom: 5px;
}

.additional-attributes-item {
  padding: 0 0 5px;
  margin: 0;
  font-size: 19px;
  line-height: 1.43em;

  dd {
    margin-inline-start: 0;
  }
}

.additional-attributes-year {
  padding: 0 0 10px;
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.375em;
  color: $red-color;
  text-transform: none;

  dd {
    margin-inline-start: 0;
  }
}

.history-list-title {
  font-size: 43px;
  font-weight: 300;
  line-height: 1.1em;
  text-transform: uppercase;
}

.history-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
  width: 66.67%;
  padding: 0 0 40px;
  margin: 0;
  list-style: none;
  @media #{$screen-sm-max} {
    width: 100%;
  }
}

.history-list-item {
  padding-bottom: 20px;
  border-bottom: 1px solid #b6b6b6;

  &:last-of-type {
    border-bottom: 0;
  }
}

.item-link {
  color: currentColor;
  text-decoration: none;

  &:hover {
    .item-year {
      color: #f7637b;
    }

    .item-title {
      color: $red-color;
    }
  }
}

.item-year {
  display: block;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 1.375em;
  color: $red-color;
}

.item-title {
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.43em;
  color: #181818;
}

.item-text {
  font-size: 15.6px;
  font-weight: 400;
  line-height: 1.54em;
  color: #6e6e6e;
}
