@import '../../variables';

.container {
  position: relative;
  height: 50vh;
  color: #fff;

  @media (max-width: 1024px) {
    max-height: 340px;
  }

  @media #{$screen-sm-max} {
    width: 100% !important;
    max-height: 460px;
  }

  &.main {
    height: 80vh;
    min-height: 600px;

    @media (max-width: 1024px) {
      min-height: auto;
      max-height: 400px;
    }

    @media #{$screen-sm-max} {
      max-height: 460px;
    }

    &::before {
      content: '';
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      height: 100%;
      background-image: radial-gradient(rgba(24, 24, 24, 0) 0%, rgba(24, 24, 24, 0.8) 100%);
    }
  }

  &:not(:first-of-type) {
    margin-top: 8px;
  }

  &:hover {
    .image img {
      transform: scale(1.1);
    }
  }
}

.image {
  position: relative;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    transition: transform 0.5s ease;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 50%;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 80%);
  }
}

.container:nth-of-type(2) .image,
.container:nth-of-type(5) .image {
  margin-right: 8px;
}

.detail {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  max-width: 600px;
  padding-right: 16px;
  padding-bottom: 40px;
  padding-left: 16px;
  margin-left: 52px;
  @media (max-width: 1024px) {
    margin-left: 24px;
  }
  @media #{$screen-xs-only} {
    margin-left: 16px;
  }
}

.label {
  display: inline-block;
  padding: 3px 4px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  background: $red-color;
}

.title {
  margin-bottom: 2px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.16667em;
  @media #{$screen-xs-only} {
    font-size: 18px;
  }
}

.main .title {
  font-size: 40px;
  @media #{$screen-xs-only} {
    font-size: 18px;
  }
}

.description {
  color: #ececec;
  @media #{$screen-xs-only} {
    font-size: 13px;
  }
}

.link {
  display: inline-block;
  margin-top: 16px;
  font-size: 18px;
  font-weight: 600;
  color: #ececec;
  text-decoration: none;
  @media #{$screen-xs-only} {
    font-size: 16px;
  }
}
