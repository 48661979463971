@import '../../variables';

.container {
  position: relative;
}

.images-count {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 126px;
  height: 126px;
  color: #181818;
  text-align: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);
  background-image: url('https://www.history.com/assets-global/gallery.svg?v=2021-03-15-222401-56f59e6-019317');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  border: 0;
  border-radius: 99px;
  transition: background-color 0.5s ease;
  transform: translate(-50%, -50%);

  &:hover {
    background-color: $red-color;
  }
}

.images-count-text {
  position: relative;
  top: 3px;
  left: -3px;
  font-size: 19.2px;
  font-weight: 700;
}
