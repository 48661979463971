@import '../../variables';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
}

.image {
  width: 80vw;
  height: 80vh;
  background: no-repeat center;
  background-size: contain;
}
