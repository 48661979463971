// general
$default-font-family: 'Open Sans', sans-serif;
$default-font-size: 16px;
$default-font-size-xs: 14px;
$default-padding: 17px;
$side-padding-sm: 35px;
$side-padding-xs: 20px;

// responsive
$screen-xs-only: 'screen and (max-width: 767px)';
$screen-sm-only: 'screen and (min-width: 768px) and (max-width: 1023px)';
$screen-sm-min: 'screen and (min-width: 768px)';
$screen-sm-max: 'screen and (max-width: 1023px)';
$screen-sm-landscape-only: 'screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)';
$screen-md-only: 'screen and (min-width: 1024px) and (max-width: 1279px)';
$screen-md-min: 'screen and (min-width: 1024px)';
$screen-lg-max: 'screen and (max-width: 1366px)';

// colors
$red-color: #e80c30;
$dark-red-color: #b81419;
$black-color: #000;
$white-color: #fff;

// z-index
$z-index-header: 2;
$z-index-overlay: 99;
$z-index-burger-menu: 100;

// sizes
$grid-cell-width: 23px;
$grid-cell-height: 28px;
$limit: 980px;
$header-height: 87px;
$header-height-sm: 87px;
$header-height-xs: 53px;
$footer-padding-top: 78px;
$footer-padding-bottom: 40px;
$page-side-padding: 60px;
$page-side-padding-md: 20px;
$page-side-padding-sm: 25px;
$page-side-padding-xs: 9px;
$card-width: 309px;
$card-width-xs: 281px;
$card-side-padding: 26px;
$card-bottom-padding: 58px;
$card-bottom-padding-xs: 30px;
$button-height: 60px;
$button-height-large: 70px;

// borders
$borders-gap: 5px;
$border-size: 1px;
$border-radius: 2px;
$light-border-color: #dfdfdf; // default color
$dark-border-color: #2c2c2c; // used in footer
$light-border: $border-size solid $light-border-color; // default border
$dark-border: $border-size solid $dark-border-color; // used in footer

// misc
$formFieldPadding: 13px;
$headerTransition: transform 0.3s ease;
$base-transition: all 0.1s ease-out;

// links
@mixin link {
  color: $red-color;
  text-decoration: none;
  transition: opacity 0.1s ease-out;

  &:hover {
    opacity: 0.8;
  }
}
