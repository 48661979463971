@import '../../variables';

.shows-page {
  //todo
}

.hero-asset {
  position: relative;
  width: 100%;
  height: 64vh;
  min-height: 500px;
  overflow: hidden;
  @media #{$screen-sm-max} {
    height: 360px;
    min-height: auto;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
  }
}

.hero-meta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 160px;
  padding: 16px 0 24px;
  background: #fafafa;
}

.hero-title {
  max-width: 720px;
  margin: 0 auto;
  font-size: 29px;
  font-weight: 600;
  line-height: 1.2em;
  text-align: center;
  @media #{$screen-sm-max} {
    font-size: 21px;
  }
}

.series-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 440px;
  padding: 8px 0 0;
  margin-top: 24px;
  list-style: none;

  @media #{$screen-sm-max} {
    width: 100%;
  }

  li {
    height: 37px;
    padding: 0 16px 0 17px;
    font-size: 14px;
    color: $black-color;
    text-align: center;

    &:nth-of-type(2) {
      border-left: 1px solid #ccc;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $black-color;
      text-decoration: none;
      transition: all 0.3s ease-out;

      &:hover {
        opacity: 0.6;
      }

      span {
        padding-bottom: 4px;
        font-size: 100%;
        font-weight: 600;
        vertical-align: baseline;
      }
    }
  }
}

.show-landing {
  max-width: 720px;
  padding: 40px 16px;
  margin: 0 auto;

  iframe {
    max-width: 100%;
  }

  p {
    margin: 0 0 25px;
    font-size: 16px;
    line-height: 1.56;
    letter-spacing: normal;
  }

  h3 {
    position: relative;
    padding-top: 7px;
    margin: 40px 0 16px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: $black-color;
    text-align: center;
    text-transform: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: calc(50% - 12px);
      width: 24px;
      height: 4px;
      background-color: #ff124d;
    }
  }
}
