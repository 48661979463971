@import '../../variables';

.history-socials {
  margin: 0 0 30px;
}

.history-socials-list {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.history-social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: none;
  border: 0;
  transition: $base-transition;

  svg {
    display: block;
  }

  &.facebook {
    color: #4267b2;

    svg {
      width: 14px;
      height: 26px;
    }

    &:hover {
      color: $white-color;
      background-color: #4267b2;
    }
  }

  &.twitter {
    color: #4099ff;

    svg {
      width: 25px;
      height: 20px;
    }

    &:hover {
      color: $white-color;
      background-color: #4099ff;
    }
  }

  &.pinterest {
    color: #cb2027;

    svg {
      width: 25px;
      height: 20px;
    }

    &:hover {
      color: $white-color;
      background-color: #cb2027;
    }
  }

  &.print {
    color: #7a7a7a;

    svg {
      width: 25px;
      height: 20px;
    }

    &:hover {
      color: $white-color;
      background-color: #7a7a7a;
    }
  }
}
