@import '../../variables';

.shows-page {
  //todo
}

.content {
  width: 95%;
  max-width: 1100px;
  padding: 60px 0;
  margin: 0 auto;
}

.content-title {
  margin: 0 0 20px;
  font-size: 1.313em;
  font-weight: normal;
  text-transform: uppercase;
  @media #{$screen-xs-only} {
    margin-bottom: 20px;
  }
}

.content-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  padding: 0;
  margin: 0;
  list-style: none;
  @media #{$screen-sm-max} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }
  @media #{$screen-xs-only} {
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
}

.content-item {
  display: block;
  color: $black-color;
  text-decoration: none;

  img {
    display: block;
    width: 100%;
  }

  &:hover {
    img {
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
      transition: all 0.3s ease-in-out;
    }
  }
}

.content-item-detail {
  height: 100px;
  padding: 8px 0 16px;
  line-height: 1.2em;

  h3 {
    margin: 0;
    font-size: 1.125em;
    font-weight: 600;
    line-height: 130%;
    color: $black-color;
    @media #{$screen-xs-only} {
      font-size: 16px;
    }
  }

  small {
    font-size: 0.882em;
    line-height: 1.6em;
    color: rgba(0, 0, 0, 0.6);
  }
}

.content-shaded {
  background: #e2e2e2;
  border-top: 1px solid #ddd;

  .content-title {
    color: rgba(0, 0, 0, 0.6);
  }

  .content-list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
    @media #{$screen-xs-only} {
      grid-template-columns: 1fr;
      grid-gap: 5px;
    }
  }

  .content-item {
    height: 110px;
    padding: 16px 20px;
    background: #fff;
    transition: all 0.2s ease-out;

    @media #{$screen-xs-only} {
      padding: 12px;
    }

    h3 {
      margin: 0 0 4px;
      @media #{$screen-sm-max} {
        font-size: 16px;
      }
      @media #{$screen-xs-only} {
        font-size: 14px;
      }
    }

    &:hover {
      background: rgba(240, 79, 105, 0.6);

      h3 {
        color: $black-color;
      }

      small {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.content-inner {
  width: 88.88%;
  max-width: 1100px;
  padding: 30px 0;
}

.episodes {
  float: right;
  width: 136px;
  height: 48px;

  strong {
    display: inline-block;
    width: 68px;
    padding: 0 10px 4px 14px;
    font-size: 24px;
    font-weight: 300;
    color: $black-color;
    text-align: right;
    text-transform: uppercase;
    border-left: 1px solid rgba(0, 0, 0, 0.16);
  }

  p {
    float: right;
    width: 68px;
    padding: 2px 0 8px;
    margin: 0;
    font-size: 10px;
    line-height: 1.1em;
    color: rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
  }
}

.content-shaded-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  @media #{$screen-xs-only} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.search-box {
  position: relative;
  @media #{$screen-xs-only} {
    width: 100%;
  }
}

.show-keyword {
  width: 300px;
  padding-right: 25px;
  font-size: 14px;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #888;
  outline: none;
  @media #{$screen-xs-only} {
    width: 100%;
  }
}

.icon-search {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 18px;
  height: 18px;
  color: #888;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23888' d='M16 14.1722C16 14.3691 15.9161 14.5659 15.7762 14.7065L14.7133 15.775C14.5734 15.9156 14.4056 16 14.1818 16C13.986 16 13.7902 15.9156 13.6504 15.775L9.56643 11.6696C9.09091 11.9789 8.55944 12.2039 8.02797 12.3726C7.46853 12.5413 6.90909 12.6257 6.2937 12.6257C5.42657 12.6257 4.61539 12.4569 3.83217 12.1195C3.07693 11.7821 2.4056 11.3322 1.84616 10.7698C1.25874 10.2074 0.811194 9.53251 0.503502 8.77329C0.167837 8.01406 0 7.17048 0 6.29877C0 5.42707 0.167837 4.6116 0.503502 3.85237C0.839166 3.09315 1.28671 2.41828 1.84616 1.85589C2.43357 1.2935 3.07693 0.843585 3.83217 0.506151C4.58742 0.168717 5.3986 0 6.2937 0C7.16084 0 7.97203 0.168717 8.75525 0.506151C9.51049 0.843585 10.1818 1.2935 10.7413 1.85589C11.3287 2.41828 11.7762 3.09315 12.0839 3.85237C12.4196 4.6116 12.5874 5.42707 12.5874 6.29877C12.5874 6.88928 12.5035 7.47979 12.3357 8.04218C12.1678 8.60457 11.9441 9.11072 11.6364 9.58875L15.7203 13.6942C15.9161 13.8067 16 13.9754 16 14.1722ZM7.97203 10.1511C8.47552 9.92619 8.92308 9.61687 9.31469 9.25132C9.7063 8.85765 9.98601 8.40773 10.2098 7.90158C10.4336 7.39543 10.5455 6.83304 10.5455 6.27065C10.5455 5.68014 10.4336 5.14587 10.2098 4.63972C9.98601 4.13357 9.67832 3.68366 9.31469 3.28998C8.92308 2.89631 8.47552 2.61511 7.97203 2.39016C7.46853 2.1652 6.90909 2.05272 6.34965 2.05272C5.76224 2.05272 5.23077 2.1652 4.72727 2.39016C4.22377 2.61511 3.77622 2.92443 3.38462 3.28998C2.99301 3.68366 2.71328 4.13357 2.48951 4.63972C2.26573 5.14587 2.15385 5.70826 2.15385 6.27065C2.15385 6.86116 2.26573 7.39543 2.48951 7.90158C2.71328 8.40773 3.02098 8.85765 3.38462 9.25132C3.77622 9.64499 4.22377 9.92619 4.72727 10.1511C5.23077 10.3761 5.79021 10.4886 6.34965 10.4886C6.90909 10.4886 7.46853 10.3761 7.97203 10.1511Z' /%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.icon-cancel {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 18px;
  height: 18px;
  padding: 0;
  color: #888;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23888' d='M1 1L16 16' stroke-width='2' stroke-linecap='round'/%3E%3Cpath stroke='%23888' d='M1 16L16 1' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 0;
}
