@import '../../variables';

$burger-button-size: 55px;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 60px;
  color: #fff;
  background-color: #000;

  @media #{$screen-lg-max} {
    padding: 20px 40px;
  }

  @media #{$screen-sm-max} {
    padding: 10px 14px;
  }

  &.main {
    position: absolute;
    z-index: 600;
    width: 100%;
    background-color: transparent;
  }
}

.image {
  width: 96px;
  margin-right: 90px;

  @media #{$screen-lg-max} {
    margin-right: 60px;
  }

  @media #{$screen-sm-max} {
    width: 56px;
  }
}

.half {
  display: flex;
  align-items: center;
}

.link {
  margin-right: 50px;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  transition: color 0.25s ease;

  &,
  &:active,
  &:visited {
    color: inherit;
  }

  &:hover,
  &:global(.active) {
    color: $red-color;
  }
}


.burger-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: $black-color;

  @media #{$screen-xs-only} {
    display: block;
    padding: 55px 0 0;
    background-color: #303037;
  }
}

.burger-menu-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: $burger-button-size;
  height: $burger-button-size;
  padding: 0;
  color: inherit;
  text-decoration: none;
  background: none;
  border: 0;

  @media #{$screen-xs-only} {
    top: 0;
    right: 0;
    border-left: 1px solid $black-color;
  }
}

.burger-menu-inner {
  width: 100%;
  min-height: 50%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-color: rgba(255, 255, 255, 0.1);

  @media #{$screen-xs-only} {
    display: block;
    background: transparent;
  }
}

.burger-menu-left {
  display: flex;
  justify-content: flex-end;
  padding: 50px;
  border-right: 1px solid $black-color;

  @media #{$screen-xs-only} {
    display: none;
  }

  a {
    display: block;
  }
}

.burger-menu-right {
  padding: 0;
}

.burger-menu-list {
  display: grid;
  grid-template-columns: 1fr;
}

.burger-menu-link {
  display: block;
  padding: 16px 16px 16px 24px;
  color: rgba(255, 255, 255, 0.56);
  text-decoration: none;
  border-bottom: 1px solid $black-color;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;

  @media #{$screen-xs-only} {
    padding: 20px 16px;
    border-bottom: 1px solid #151515;
    color: $white-color;
  }

  &:visited {
    color: rgba(255, 255, 255, 0.56);
    @media #{$screen-xs-only} {
      color: $white-color;
    }
  }
}

.burger-menu-logo {
  display: block;
  width: 100px;
}

.line {
  position: absolute;
  top: 20px;
  left: 15px;
  display: block;
  width: 25px;
  height: 3.3px;
  background-color: currentColor;
  transition: 0.25s ease-in-out;

  &:nth-of-type(2) {
    top: 27px;
  }

  &:nth-of-type(3) {
    top: 34px;
  }

  &:nth-child(1) {
    top: 4px;
    left: 50%;
    width: 0;
    opacity: 0;
  }

  &:nth-child(2) {
    transform: rotate(45deg);
  }

  &:nth-child(3) {
    top: 27px;
    transform: rotate(-45deg);
  }
}

.menu {
  @media #{$screen-sm-max} {
    display: none;
  }
}


.mobile-logo {
  display: none;

  @media #{$screen-xs-only} {
    display: block;
    padding: 50px 20px 16px;
    border-top: 1px solid #151515;
    border-bottom: 1px solid #151515;

    a {
      display: block;
      font-size: 32px;
      font-weight: bold;
      color: $white-color;
      text-decoration: none;
      &:visited {
        color: $white-color;
      }
    }
  }
}
