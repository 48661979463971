@import '../../../variables';

$burger-button-size: 55px;

.button {
  position: relative;
  display: none;
  width: $burger-button-size;
  height: $burger-button-size;
  padding: 0;
  color: inherit;
  text-decoration: none;
  background: none;
  border: 0;

  @media #{$screen-sm-max} {
    display: block;
  }

  &:hover {
    text-decoration: none;
  }
}

.line {
  position: absolute;
  top: 20px;
  left: 15px;
  display: block;
  width: 25px;
  height: 3.3px;
  background-color: currentColor;
  transition: 0.25s ease-in-out;

  &:nth-of-type(2) {
    top: 27px;
  }

  &:nth-of-type(3) {
    top: 34px;
  }
}

.cross {
  /*.line {
    &:nth-child(1) {
      top: 4px;
      left: 50%;
      width: 0;
      opacity: 0;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      top: 27px;
      transform: rotate(-45deg);
    }
  } */
}
